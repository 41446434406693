.cardCategory {
    padding-right: 12px;
}

.deviceAlias {
    color: cornflowerblue;
}

.deviceLocationContainer {
    height: 50px;
}

.actionButtonsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.addDeviceButtonContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.devStatusIconsContainer {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.devStatusIcon {
    width: 24px !important;
    height: 24px !important;
}

.deviceModalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2000;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box
}

.deviceSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.deviceCompactViewContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: 10px !important;
    border: solid 1px;
    border-radius: 16px;
    border-color: #78c9d7;

    margin: 2px;
    /* height: 150px; */
}

.deviceDetailsContainer {
    display: flex;
    flex-direction: column;
}

.deviceActionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}

.deviceDetailsIconsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-size: initial;
}

.deviceActionsMenuButton {
    padding: 0px !important;
}

.deviceActionsMenuIcon {
    width: 24px !important;
    height: 24px !important;
}
